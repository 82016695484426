<template>
  <div ref="container" class="importantKey w100">
    <Title :isShowSwitch="isShowSwitch" @changeSwitch="changeSwitch"  @curveFun="curveFun">医生团队</Title>
    <div class="cardContainer">
      <StatisticsCard :name="i.name" :value="i.value" :key="i.name" v-for="i in cardData">
      </StatisticsCard>
    </div>
    <BaseEchart :option="option" style="height: 370px; width: 100%"></BaseEchart>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { barChart } from '../common'
import BaseEchart from '@/components/BaseEchart';
import Title from '../components/Title';
import StatisticsCard from '../components/StatisticsCard'
import { fetchRegionSummaryNotations, findValueByName } from '../../../request/api';

export default {
  components: {
    StatisticsCard,
    BaseEchart,
    Title
  },
  data() {
    return {
      option: null,
      cardData: [{ name: '医生团队总数', value: 0 }, { name: '医护人员总数', value: 0 }, { name: '服务社区数', value: 0 }, { name: '总服务数', value: 0 }],
      isShowSwitch:true,
      isSwitchOpen:true,
      period:1
    };
  },
  computed: {
    ...mapState('mapData', ['addressInfo', 'mapLevel', 'mapDataArr']),
    // cardDataByLevel() {
    //   return this.cardData.map(c => {
    //     return { ...c, value: Math.round(c.value / (this.mapLevel + 1)/this.period) }
    //   })
    // },
    currentMonthPeriod(){
      return this.$store.state.homePage.currentMonthPeriod
    },
    monthPeriod(){
      return this.$store.state.homePage.monthPeriod
    },
  },
  watch: {
    mapDataArr: {
      handler(){
        if(this.mapDataArr.length > 0){
          this.init()
        }
      },
      immediate: true
    },
    monthPeriod:{
      handler(){
        this.selectTime()
      },
    }
  },
  mounted() {
    // this.selectTime()
  },
  methods: {
    changeSwitch(val){
      this.isSwitchOpen = val
      this.selectTime()
    },
    curveFun(name){
      this.$emit('curFun', name, i)
    },
    selectTime(){
      if(this.isSwitchOpen){
        this.period = this.monthPeriod
      }else{
        this.period = this.currentMonthPeriod
      }
      this.init()
    },
    async init(){
      let par = {
        itemName: '医生团队数量',
        descendantLevel: 1,
        fullTime: this.isSwitchOpen
      }
      fetchRegionSummaryNotations(par).then(res => {
        let data = res.data.collection
        let max = 4
        if(data.length > 0) max = data[0].intValue
        const arrData = res.data.collection.map((d) => {
          if(d.intValue > max) max = d.intValue
          return { name: d.chinaRegionDto.title, value: d.intValue };
        })
        this.option = barChart({ xData: arrData.map(a => a.name), yData: arrData.map(a => a.value) }, {max: max + 6})
      })
      // const arrData = this.mapDataArr.map((d) => {
      //   return { name: d.regionName, value: randomByLevel({ max: 1000, level: this.mapLevel }) };
      // })
      // this.option = barChart({ xData: arrData.map(a => a.name), yData: arrData.map(a => Math.round(a.value/this.period)) }, {max: 1000})
      const data = await fetchRegionSummaryNotations({itemName: '医生团队数量;家庭医生数量;服务社区数量;总服务数量;', fullTime: this.isSwitchOpen})
      let team = findValueByName('医生团队数量', data?.data?.collection)
      this.$store.state.homePage.block.teamDor = team
      this.cardData[0].value = team
      this.cardData[1].value = findValueByName('家庭医生数量', data?.data?.collection)
      this.cardData[2].value = findValueByName('服务社区数量', data?.data?.collection)
      this.cardData[3].value = findValueByName('总服务数量', data?.data?.collection)
    }
  },
};
</script>

<style lang="scss" scoped>
.importantKey {
  border: 2px solid #264471;
  box-sizing: border-box;
  position: relative;
  background: rgba(5, 41, 92, .6);
  border: 2px solid rgba(17, 94, 205, .2);
  .nullData {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cardContainer {
    display: flex;
    gap: 8px;
    padding: 8px;
  }
}
</style>