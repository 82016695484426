<template>
	<div class="bordered">
		<Title :isShowSwitch="isShowSwitch" @changeSwitch="changeSwitch" @curveFun="curveFun">服务人群</Title>
		<div class="m_xares">
			<div class="line" v-for="(v, i) in essenArr" :key="i">
				<img src="../assets/crew.png" class="crew">
				<p>{{ v.name }}</p>
				<div class="value">
					<countTo :startVal="0" :endVal="v.value" :duration="1000"></countTo>
				</div>
			</div>
		</div>
		<BaseEchart :option="option" style="width: 100%; height: 254px"></BaseEchart>
	</div>
</template>
<script>
import BaseEchart from '@/components/BaseEchart';
import Title from '../components/Title';
import { barChart } from '../common';
import countTo from 'vue-count-to';
import { CountAnimationDuration } from '@/views/homePage/common';
import { mapState } from 'vuex';
import { fetchRegionSummaryNotations, findValueByName, mapDataNormal } from '../../../request/api';

export default {
	components: { Title, BaseEchart, countTo },
	data() {
		return {
			CountAnimationDuration,
			option: undefined,
			essenArr: [{ value: 0, name: '覆盖人群总数' }, { value: 0, name: '覆盖家庭总数' }],
			isShowSwitch: true,
			isSwitchOpen: true,
			period: 1
		};
	},
	computed: {
		...mapState('mapData', ['mapLevel', 'mapDataArr', 'addressInfo']),
		essenFun() {
			return this.essenArr.map(s => {
				return {
					...s,
					value: Math.round(s.value / (this.mapLevel + 1)/this.period)
				}
			})
		},
		currentMonthPeriod() {
			return this.$store.state.homePage.currentMonthPeriod
		},
		monthPeriod() {
			return this.$store.state.homePage.monthPeriod
		},
	},
	watch: {
		mapDataArr: {
      handler(){
        if(this.mapDataArr.length > 0){
          this.init()
        }
      },
      immediate: true
    },
		monthPeriod: {
			handler() {
				this.selectTime()
			},
		}
	},
	mounted() {
		// this.init();
	},
	methods: {
		async init(){
			// const dataX = arry.map((v, i) => {
			// 	return { name: v.name, value: v.value }
			// })
			// this.option = barChart({ xData: dataX.map(d => d.name), yData: dataX.map(d => Math.round(d.value / (this.mapLevel + 1)/this.period)) })
			let par = {
        itemName: '家庭医生-普通人群;家庭医生-65岁以上老人签约;家庭医生-计生家庭;家庭医生-其他',
        fullTime: this.isSwitchOpen
      }
			let fetchData = await fetchRegionSummaryNotations(par)
			let arry = [
				{ name: '普通人群', value: 0 },
				{ name: '65岁以上老人签约', value: 0 },
				{ name: '计生家庭', value: 0 },
				{ name: '其他', value: 0 }
			];
			let bars = mapDataNormal(arry, fetchData?.data?.collection || []);
			const max = bars.reduce((max, cur) => {
				max = Math.max(max, cur.value)
				return max
			}, 4)
			this.option = barChart({xData: bars.map((d) => d.name), yData: bars.map((d) => d.value)}, {max: max + 6});
			let data = await fetchRegionSummaryNotations({itemName: '家庭总数;居民总数;', fullTime: this.isSwitchOpen})
			this.essenArr[0].value = findValueByName('居民总数', data?.data?.collection)
			this.$store.state.homePage.block.crowdDor = findValueByName('居民总数', data?.data?.collection)
      this.essenArr[1].value = findValueByName('家庭总数', data?.data?.collection)
    },
		changeSwitch(val) {
			this.isSwitchOpen = val
			this.selectTime()
		},
		curveFun(name) {
			this.$emit('curFun', name, i)
		},
		selectTime(){
      if(this.isSwitchOpen){
        this.period=this.monthPeriod
      }else{
        this.period=this.currentMonthPeriod
      }
	  this.init()
    }
	},
};
</script>
<style lang="scss" scoped>
@import '@/theme/bigScreen.scss';

.bordered {
	background: rgba(5, 41, 92, .6);
	border: 2px solid rgba(17, 94, 205, .2);
}

.m_xares {
	padding: 0 5px 15px;
	text-align: center;

	.line {
		width: 46%;
		height: 184px;
		background: rgba(23, 116, 255, 0.25);
		margin: 15px 2% 0;
		display: inline-block;
	}

	p {
		font-size: 16px;
		color: #fff;
		padding: 4px 0;
	}

	.crew {
		display: inline-block;
		width: 76px;
		height: 76px;
		margin: 30px 0 10px;
	}
}
</style>