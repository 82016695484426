<template lang="">
	<el-row class="page" style="margin: 0" :gutter="16">
		<el-col :span="6">
			<LeftTop></LeftTop>
			<LeftBottom class="mt16"></LeftBottom>
		</el-col>
		<el-col :span="12">
			<CenterTop class="mb16"></CenterTop>
			<MapData :mapType="3" class="map" :height="664" :mapindex="0"></MapData>
			<div class="m_xtrend mt16">
				<div class="title">
					<div class="indicator"></div>
					<el-select v-model="lineTit" placeholder="请选择" @change="curveFun($event)">
						<el-option v-for="(v, i) in optionTit" :key="i" :label="v.label" :value="v.value"> </el-option>
					</el-select>
				</div>
				<BaseEchart :option="option" style="width: 100%; height: 154px"></BaseEchart>
			</div>
		</el-col>
		<el-col :span="6">
			<RightTop></RightTop>
			<RightBottom></RightBottom>
		</el-col>
		<div class="content_wrap" v-if="isshowList">
			<div class="content">
				<div class="content-title">
					<div class="label">家庭医生成员</div>
					<div class="close" @click="close">
						<img src="../../../assets/imgs/visitCloseBtn.png" alt="" />
					</div>
				</div>
				<div class="content-text" slot="reference">
					<div class="personInfo">
						<div class="personInfo-doctor" v-for="item in doctorList">
							<div class="imgs">
								<img :src="imgCover(item)" alt="" />
							</div>
							<div class="doctorName">
								<p class="name">
									{{ item.name }}
									<img :style="{cursor: toCall.visible ? 'not-allowed' : 'pointer', filter: toCall.visible ? 'grayscale(1)' : ''}" @click="openVideoCall(item)" src="../../../assets/imgs/video.png" alt="" />
								</p>
								<p class="age"><span>年&emsp;&emsp;龄：</span> {{ calcAge(item.birthDate) }}</p>
								<p class="speciality"><span>专&emsp;&emsp;业：</span>{{ item.positionDictionaryItem.title }}</p>
								<p class="practiceUnit one-text-overflow"><span>执业单位：</span> {{ item.medicalOrganization.title }}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
      <div v-if="toCall.visible" class="tocall-popover">
        <div class='chatCard'>
          <div class="chatDesc">
            <img style="width: 60px;height: 60px" :src="imgCover(toCall.item)">
            <div class="info">
               <div>{{toCall.item.name}}</div>
               <div>{{toCall.item.phoneNumber}}</div>
               <div class="addr"><i class=el-icon-location></i>{{toCall.item.medicalOrganization.title}}</div>
            </div>
          </div>
          <CharRoom class="room" @onEnd="onEnd" :toAccid="toCall.toAccid"></CharRoom>
        </div>
      </div>
		</div>
	</el-row>
</template>
<script>
import { mapState } from 'vuex';
import dayjs from 'dayjs';
import '@/assets/icon-font/ABDUCTION2000.css';
import BaseEchart from '@/components/BaseEchart';
import LeftTop from './LeftTop.vue';
import LeftBottom from './LeftBottom.vue';
import CenterTop from './CenterTop.vue';
import RightTop from './RightTop.vue';
import RightBottom from './RightBottom.vue';
import MapData from '@/views/homePage/overview/mapData.vue';
import CharRoom from '../charRoom.vue';
import qs from 'qs'

export default {
	components: { CharRoom, MapData, LeftTop, LeftBottom, CenterTop, BaseEchart, RightTop, RightBottom },
	data() {
		return {
			toCall: { item: null, visible: false, toAccid: '' },
			mapheight: 50,
			option: null,
			first: [
				{
					name: '',
					value: [90, 100, 200, 100, 110, 280, 150, 260, 160, 90, 210, 50, 90, 100, 200, 100, 110, 280, 150, 260, 160, 90, 210, 50],
				},
			],
			second: [
				{
					name: '健康档案',
					value: [180, 240, 160, 190, 130, 240, 10, 100, 160, 80, 45, 280, 180, 240, 160, 190, 130, 240, 10, 100, 160, 80, 45, 280],
				},
				{
					name: '健康体检',
					value: [210, 220, 180, 23, 150, 260, 140, 120, 180, 100, 65, 100, 210, 220, 180, 23, 150, 260, 140, 120, 180, 100, 65, 100],
				},
				{
					name: '健康教育',
					value: [0, 280, 70, 230, 170, 280, 10, 140, 200, 120, 85, 40, 0, 280, 70, 230, 170, 280, 10, 140, 200, 120, 85, 40],
				},
				{
					name: '传染病管理',
					value: [60, 14, 60, 170, 110, 220, 250, 80, 140, 60, 85, 0, 60, 14, 60, 170, 110, 220, 250, 80, 140, 60, 85, 0],
				},
			],
			third: [
				{
					name: '',
					value: [120, 280, 210, 180, 120, 280, 110, 220, 102, 56, 35, 40, 120, 280, 210, 180, 120, 280, 110, 220, 102, 56, 35, 40],
				},
			],
			fourth: [
				{
					name: '',
					value: [280, 200, 200, 160, 110, 280, 150, 260, 60, 30, 200, 110, 280, 200, 200, 160, 110, 280, 150, 260, 60, 30, 200, 110],
				},
			],
			lineArr: [],
			isTit: false,
			isshowList: false,
			doctorList: [],
			lineTit: '医生团队',
			optionTit: [
				{
					value: '医生团队',
					label: '建设医生团队增长趋势',
				},
				{
					value: '居民健康管理',
					label: '建设居民健康管理增长趋势',
				},
				{
					value: '服务人群',
					label: '建设服务人群增长趋势',
				},
				{
					value: '服务质量',
					label: '建设服务质量增长趋势',
				},
			],
			color: [
				{ up: '#0EB7F4', down: 'rgba(14,183,244,.1)' },
				{ up: '#FF7B00', down: 'rgba(255,123,0,.1)' },
				{ up: '#58E41B', down: 'rgba(88,228,27,.1)' },
				{ up: '#F4AB0E', down: 'rgba(244,171,14,.1)' },
			],
		};
	},
	computed: {
		...mapState('mapData', ['addressInfo', 'mapLevel', 'mapDataArr', 'heads']),
		heads() {
			return this.$store.state.homePage.heads;
		},
		timeRange() {
			return this.$store.state.homePage.timeRange;
		},
		timeRangeAndMapLevel() {
			return this.timeRange + this.mapLevel;
		},

	},
	beforeCreate() {
		this.$store.state.homePage.heads = { name: '', index: 1 };
	},
	watch: {
		mapLevel: {
			immediate: true,
			handler: function () {
				if (this.lineArr.length == 0) this.lineArr = this.first;
				let as = this.color;
				this.lineArr.map((v, i) => {
					v.color = as[i];
				});
				this.wireFun();
			},
		},
		timeRangeAndMapLevel: {
			handler(val) {
				let as = this.color;
				this.lineArr.map((v, i) => {
					v.color = as[i];
				});
				this.wireFun();
			},
			immediate: true,
		},
		heads: {
			immediate: true,
			handler: function (val) {
				if (val.name != '') {
					this.curveFun(val);
				}
			},
		},
	},
	methods: {
    imgCover(item){
      return item?.attachmentDtoMap?.COVER?.[0]?.link
    },
    calcAge(birthday){
      const d = dayjs.duration(dayjs().diff(dayjs(birthday)))
      return d.get('year')
    },
    onEnd(){
      this.toCall.visible = false
      this.toCall.toAccid = ''
    },
		deal(arr) {
			return arr.map((d) => {
				return Math.round(d / (this.mapLevel + 1));
			});
		},
		wireFun() {
			let data = [];
			let start = this.timeRange[0];
			let end = this.timeRange[1];
			let months = [];
			while (start <= end) {
				months.push(start.getMonth() + 1);
				start = dayjs(start).add(1, 'month').toDate();
			}
			let us = {};
			this.lineArr.map((v) => {
				us = {
					name: v.name,
					data: this.deal(v.value),
					symbol: 'circle',
					type: 'line',
					smooth: true,
					symbol: 'none',
					lineStyle: {
						color: v.color.up,
					},
					itemStyle: {
						color: v.color.up,
					},
					areaStyle: {
						color: {
							type: 'linear',
							x: 0,
							y: 0,
							x2: 0,
							y2: 1,
							colorStops: [
								{
									offset: 0,
									color: v.color.up, // 0% 处的颜色
								},
								{
									offset: 1,
									color: v.color.down, // 100% 处的颜色
								},
							],
						},
					},
				};
				data.push(us);
			});
			this.option = {
				axisLabel: {
					color: '#fff',
					fontSize: 12,
				},
				legend: {
					show: true,
					icon: 'stack',
					top: 6,
					itemWidth: 10,
					itemHeight: 5,
					itemColor: '#fff',
					textStyle: {
						fontSize: 16,
						color: '#fff', // 设置字体颜色
						padding: [0, 5], // 设置文字与图例的距离
					},
					itemGap: 20, // 设置图例项之间的间距
					itemStyle: {
						borderWidth: 0,
					},
				},
				grid: {
					left: 40,
					right: 40,
					top: 48,
					bottom: 32,
				},
				xAxis: {
					type: 'category',
					boundaryGap: false,
					splitLine: {
						show: true,
						lineStyle: {
							opacity: 0.2,
							type: [5, 5],
							dashOffset: 0,
							shadowBlur: 0,
						},
					},
					data: months.map((m) => m + '月'),
				},
				yAxis: {
					type: 'value',
					splitNumber: 4,
					min: 0,
					// max: 280,
					splitLine: {
						show: true,
						lineStyle: {
							opacity: 0.2,
							type: [5, 5],
							dashOffset: 0,
							shadowBlur: 0,
						},
					},
				},
				series: data,
			};
			this.$forceUpdate();
		},
		curveFun(val) {
			let name = val;
			if (typeof val == 'object') {
				name = val.name;
			}
			this.lineTit = name;
			if (val.index == 1) {
				this.isTit = false;
			} else {
				this.isTit = true;
			}
			let arr = [];
			if (name == '医生团队') {
				arr = this.first;
			} else if (name == '居民健康管理') {
				arr = this.second;
			} else if (name == '服务人群') {
				arr = this.third;
			} else if (name == '服务质量') {
				arr = this.fourth;
			}
			let as = this.color;
			arr.map((v, i) => {
				v.color = as[i];
			});
			this.lineArr = arr;
			this.wireFun();
		},
		openVideoCall(item) {
      this.toCall.item = item
			this.toCall.visible = true;
      this.toCall.toAccid = item.accId
		},
		close() {
			this.isshowList = false;
		},
	},
	mounted() {
		this.bus.$on('show_doctor', async (type, data) => {
			if (type == 3) {
        const resp = await this.$http('management-api/v1/MedicalWorkers.json', {params: qs.stringify({organizationIds: [data.id]})})
        this.doctorList = resp?.data?.collection
				this.isshowList = true;
			}
		});
	},
	beforeDestroy() {
		this.$store.state.homePage.heads = { name: '', index: 1 };
	},
};
</script>
<style lang="scss" scoped>
.m_xtrend {
	background: rgba(5, 41, 92, 0.3);
	position: relative;
	border: 2px solid #1d3468;

	.title {
		position: absolute;
		left: 0;
		top: 0;
		height: 32px;
		padding-left: 16px;
		font-size: 18px;
		display: flex;
		align-items: center;
		z-index: 10;
		.indicator {
			height: 16px;
			width: 4px;
			margin-right: 8px;
			background-image: linear-gradient(#308fcc, #224abe);
		}
	}
	/deep/ .el-input__inner {
		background: none;
		font-size: 16px;
		color: #fff;
		font-weight: bold;
		padding: 0;
		border: none;
	}
	/deep/ .el-input__icon {
		line-height: 32px;
		color: #1c87ff;
	}
}

.content_wrap {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	overflow: auto;
	margin: 0;
	background-color: rgba(145, 142, 142, 0.233);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 222;

  .tocall-popover {
    margin-left: 28px;
    background-color: #163D66;
    padding: 10px;
    .chatCard {
      display: flex;
      flex-direction: column;
      .chatDesc {
        display: flex;
        .info {
          margin-left: 10px;
          .addr {
            color: #0BF9FE;
          }
        }
      }
      .room {
        margin-top: 16px;
      }
    }
  }

	.content {
		width: 500px;
		height: 550px;
		background: url(../../../assets/imgs/visitGroupBg.png) no-repeat;
		background-size: 500px 550px;

		&-title {
			height: 52px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0 25px;
			background: url('../../../assets/imgs/dialogTitleBg.png') no-repeat center;
			background-size: 100% 100%;
			color: #fff;
		}

		.label {
			margin-top: 6px;
			font-size: 24px;
			line-height: 22px;
			height: 25px;
			background: url('../../../assets/imgs/线.png') no-repeat bottom;
			background-size: 100% 5px;
		}

		.close {
			display: flex;
			align-items: center;
			margin-top: 6px;

			img {
				width: 28px;
				height: 28px;
				cursor: pointer;
			}
		}

		&-text {
			max-height: 490px;
			overflow-y: auto;

			.personInfo {
				padding: 0 18px;
				padding-top: 16px;

				&-doctor {
					background: rgba(12, 49, 94, 0.65);
					border-radius: 6px;
					display: flex;
					position: relative;
					margin-top: 5px;

					.imgs {
						position: absolute;
						width: 130px;
						background-color: transparent;
						height: 148px;
						left: 18px;

						img {
              object-fit: contain;
							width: 100%;
							height: 100%;
						}
					}

					.doctorName {
						width: calc(100% - 180px);
						margin-left: 180px;
						padding: 15px 0;
						color: #fff;

						p {
							font-size: 16px;
							line-height: 14px;
							height: 14px;
							margin-bottom: 13px;

							&:last-child {
								margin-bottom: 0;
							}
						}

						.name {
							font-size: 18px;
							line-height: 16px;
							height: 16px;
							margin-top: 6px;
							margin-bottom: 28px;
							font-weight: bold;
							position: relative;

							img {
								width: 24px;
								height: 24px;
								position: absolute;
								right: 20px;
								top: -3px;
								cursor: pointer;
							}
						}
					}
				}
			}
		}
	}
}
</style>
