<template>
  <div ref="container" class="importantKey w100 mt16">
    <Title :isShowSwitch="isShowSwitch" @changeSwitch="changeSwitch"  @curveFun="curveFun">居民健康管理</Title>
    <TotalAnalysis style="marginBottom: 0" :value="analysisNum"></TotalAnalysis>
    <BaseEchart :option="option" style="height: 320px; width: 100%"></BaseEchart>
    <div class="m_xcut">
      <em v-for="(v, i) in cutArr" :key="i" :class="{'on': i == cutIdx}" @click="cutFun(i)">{{v}}</em>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { barChart } from '../common'
import { randomByLevel } from '@/views/homePage/common';
import TotalAnalysis from '../components/TotalAnalysis.vue'
import BaseEchart from '@/components/BaseEchart';
import Title from '../components/Title';
import StatisticsCard from '../components/StatisticsCard'
import { fetchRegionSummaryNotations, findValueByName } from '../../../request/api';

export default {
  components: {
    StatisticsCard,
    BaseEchart,
    Title,
		TotalAnalysis
  },
  data() {
    return {
      option: null,
			analysisNum: 0,
      showNoData: false,
      cardData: [{ name: '总人数', value: 830000 }, { name: '老人', value: 1025 }, { name: '妇女', value: 825 }, { name: '儿童', value: 2042 }, { name: '常见病', value: 159 }],
      cutIdx:0,
      cutArr:['健康档案', '健康体检', '健康教育', '传染病管理'],
      isShowSwitch:true,
      isSwitchOpen:true,
      period:1,
      itemName: '家庭医生-健康档案'
    };
  },
  computed: {
    ...mapState('mapData', ['addressInfo', 'mapLevel', 'mapDataArr']),
		valueByLevel(){
      return Math.round(this.analysisNum / (this.mapLevel + 1)/this.period)
    },
    currentMonthPeriod(){
      return this.$store.state.homePage.currentMonthPeriod
    },
    monthPeriod(){
      return this.$store.state.homePage.monthPeriod
    },
  },
  watch: {
    mapDataArr: {
      handler(){
        if(this.mapDataArr.length > 0){
          this.init()
        }
      },
      immediate: true
    },
    monthPeriod:{
      handler(){
        this.selectTime()
      }
    }
  },
  mounted() {
    // this.selectTime()
  },
  methods: {
    cutFun(i) {
      this.cutIdx = i
      let name = '家庭医生-'+this.cutArr[i]
      if(i > 0){
        name = this.cutArr[i]
      }
      this.itemName = name
      this.init()
    },
    changeSwitch(val){
      this.isSwitchOpen=val
      this.selectTime()
    },
    curveFun(name){
      this.$emit('curFun', name, i)
    },
    selectTime(){
      if(this.isSwitchOpen){
        this.period = this.monthPeriod
      }else{
        this.period = this.currentMonthPeriod
      }
      this.init()
    },
    init(){
      let par = {
        itemName: this.itemName,
        descendantLevel: 1,
        fullTime: this.isSwitchOpen
      }
      fetchRegionSummaryNotations(par).then(res => {
        let data = res.data.collection
        let max = 4
        if(data.length > 0) max = data[0].intValue
        let num = 0
        const arrData = res.data.collection.map((d) => {
          num += d.intValue
          if(d.intValue > max) max = d.intValue
          return { name: d.chinaRegionDto.title, value: d.intValue };
        })
        this.analysisNum = num
        this.$store.state.homePage.block.inmateDor = num
        this.option = barChart({ xData: arrData.map(a => a.name), yData: arrData.map(a => a.value) }, {max: max + 6})
      })

      // const arrData = this.mapDataArr.map((d) => {
      //   return { name: d.regionName, value: randomByLevel({ max: 1000, level: this.mapLevel }) };
      // })
      // this.option = barChart({ xData: arrData.map(a => a.name), yData: arrData.map(a => Math.round(a.value/this.period)) }, {max: 1000})
    }
  }
};
</script>

<style lang="scss" scoped>
.importantKey {
  border: 2px solid #264471;
  box-sizing: border-box;
  position: relative;
  background: rgba(5, 41, 92, .6);
  border: 2px solid rgba(17, 94, 205, .2);
  .nullData {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.m_xcut{
  height: 35px;
  display: flex;
  align-items: center;
  border-top: 1px solid #213861;
  em{
    flex: 1;
    font-size: 14px;
    font-weight: bold;
    color: #FFFFFF;
    font-style: normal;
    text-align: center;
    line-height: 35px;
    position: relative;
    cursor: pointer;
  }
}
.m_xcut em:not(:first-child)::before{
  position: absolute;
  content: '';
  left: 0;
  width: 1px;
  height: 12px;
  top:50%;
  margin-top: -6px;
  background: #213861;
}
.m_xcut .on{
  color:#00F0FF;
}
</style>